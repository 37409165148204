import axios from 'axios';

let apiUrl = process.env.VUE_APP_API_URL;

export default {
  getAdminRequests: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/request/all`,
      params: payload,
    });
  },
  getSingleAdminRequest: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/request/${id}`,
    });
  },
  getBookingSummaryAdmin: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/request/booking-summary/${id}`,
    });
  },
  getAdminCompanies: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/all`,
      params: payload,
    });
  },
  createCompany: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company`,
      data: payload,
    });
  },
  updateCompany: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/update`,
      data: payload,
    });
  },
  deleteCompany: (id) => {
    return axios({
      method: 'delete',
      url: `${apiUrl}/admin/company/${id}`,
    });
  },
  getCompanyList: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/list`
    });
  },
  getThirdLevelMargins: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/price-settings/margin/third-level/all`,
      params: payload,
    });
  },
  getSingleThirdLevelMargin: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/price-settings/margin/third-level/${id}`,
    });
  },
  saveThirdLevelMargin: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/price-settings/margin/third-level`,
      data: payload,
    });
  },
  updateThirdLevelMargin: (payload) => {
    return axios({
      method: 'put',
      url: `${apiUrl}/admin/price-settings/margin/third-level`,
      data: payload,
    });
  },
  deleteThirdLevelMargin: (id) => {
    return axios({
      method: 'delete',
      url: `${apiUrl}/admin/price-settings/margin/third-level/${id}`,
    });
  },
  getThirdLevelFilters: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/price-settings/margin/third-level/filters`,
    });
  },
  getSecondLevelMargins: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/price-settings/margin/second-level/all`,
      params: payload,
    });
  },
  saveSecondLevelMargin: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/price-settings/margin/second-level`,
      data: payload,
    });
  },
  getFirstLevelMargins: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/price-settings/margin/first-level/all`,
      params: payload,
    });
  },
  saveFirstLevelMargins: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/price-settings/margin/first-level`,
      data: payload,
    });
  },
  getCompanyUsers: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/${id}/users`,
    });
  },
  createUserRelevancy: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/users/relevancy`,
      data: payload,
    });
  },
  editUserRelevancy: (id, payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/users/relevancy/${id}`,
      data: payload,
    });
  },
  getSingleUserRelevancy: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/users/relevancy/${id}`,
    });
  },
  getAllUserRelevancy: (company_id, user_id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/${company_id}/users/${user_id}/relevancy`,
    });
  },
  deleteUserRelevancy: (id, company_id, user_id) => {
    return axios({
      method: 'delete',
      url: `${apiUrl}/admin/company/users/relevancy/${id}?company_id=${company_id}&user_id=${user_id}`,
    });
  },
  getUsersRelevancyList: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/users/relevancy/all`,
      params: payload,
    });
  },
  saveUsersRelevancyMultiple: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/users/relevancy/multiple`,
      data: payload,
    });
  },
  createCompanyUser: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/create-user`,
      data: payload,
    });
  },
  deleteSingleCompanyUser: (user_id, company_id) => {
    return axios({
      method: 'delete',
      url: `${apiUrl}/admin/company/user?user_id=${user_id}&company_id=${company_id}`,
    });
  },
  getRelevancyFilters: () => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/relevancy/filters`,
    });
  },
  getCompanyRelevancyList: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/relevancy/all`,
      params: payload,
    });
  },
  getCompanyRelevancyAdmin: (id) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/company/relevancy/${id}`,
    });
  },
  saveCompanyRelevancy: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/relevancy/`,
      data: payload,
    });
  },
  saveCompanyRelevancyMultiple: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/company/relevancy/multiple`,
      data: payload,
    });
  },
  updateRequestStatus: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/request/change-status`,
      data: payload,
    });
  },
  updateAdminRequest: (requestId, payload) => {
    return axios({
      method: 'put',
      url: `${apiUrl}/admin/request/${requestId}`,
      data: payload,
    });
  },
  reopenOffer: (offerId, payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/offer/${offerId}/reopen`,
      data: payload,
    });
  },
  getClients: (payload) => {
    return axios({
      method: 'get',
      url: `${apiUrl}/admin/clients`,
      params: payload,
    });
  },
  loginAsUser: (payload) => {
    return axios({
      method: 'post',
      url: `${apiUrl}/admin/tc-user/login`,
      data: payload,
    });
  }
};
